import {useState, useEffect} from 'react';
import axios from "../../utils/axios";

export default function useGetFooter() {
  const [logo, setLogo] = useState([]);
  const [social, setSocial] = useState([]);
  const [download, setDownload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await axios.get('/footer/footer_por_estado?estado=true');
      setLogo(response.data.data.filter(x => x.Seccion === 'logo'))
      setSocial(response.data.data.filter(x => x.Seccion === 'social'))
      setDownload(response.data.data.filter(x => x.Seccion === 'descargables'))
      setLoading(false);
    } catch(err) {
      console.log(err);
      setLoading(false);
      setError(err);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])
  return {logo, social, download, loading, error};
}
