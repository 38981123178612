import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {createContext, useRef, useState} from 'react';

export const ConfirmationModalContext = createContext({});

export const ConfirmationModalContextProvider = (props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const resolver = useRef();

  const handleShow = () => {
    setShowConfirmationModal(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setShowConfirmationModal(false)
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setShowConfirmationModal(false)
  };

  return (
    <ConfirmationModalContext.Provider value={{showConfirmation: handleShow}}>
      {props.children}
      <Dialog
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" align={'center'}>
          Deseas eliminar este item ?
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={handleCancel}>Cancelar</Button>
          <Button variant='contained' color='primary' onClick={handleOk} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationModalContext.Provider>
  )
};
