export const catchHtml = (html) => {
  return {
    __html: html
  }
}

export function changeBannerImg(e, setTitle, setPreview, setImg, type) {
  e.preventDefault();
  const {files} = e.target;
  const reader = new FileReader();
  reader.readAsDataURL(files[0]);
  reader.onload = () => {
    setTitle(files[0].name)
    setPreview(reader.result)
    if (type === 'mobile') {
      setImg(prevState => ({...prevState, URL_Mobile: files[0]}));
    } else {
      setImg(prevState => ({...prevState, URL_Desktop: files[0]}));
    }
  }
}

export function changeImg(e, setTitle, setPreview, setImg) {
  e.preventDefault();
  const {files} = e.target;
  const reader = new FileReader();
  reader.readAsDataURL(files[0]);
  reader.onload = () => {
    setTitle(files[0].name)
    setPreview(reader.result)
    setImg(files[0]);
  }
}

export function emptyImageSlot(setTitle, setPreview, setImg, type, view) {
  const DUMMY_IMAGE = '/static/dummy/dummy-producto.png'
  setTitle('')
  setPreview(DUMMY_IMAGE)
  if (type && view) {
    if (view === 'services') {
      if (type === 'desktop') {
        setImg(prevState => ({...prevState, Banner_Desktop: ''}));
      } else {
        setImg(prevState => ({...prevState, Banner_Mobile: ''}));
      }
    } else {
      if (type === 'desktop') {
        setImg(prevState => ({...prevState, URL_Desktop: ''}));
      } else {
        setImg(prevState => ({...prevState, URL_Mobile: ''}));
      }
    }
    setImg('')
  }
}


export function switchLogo(name) {
  switch (name) {
    case 'Facebook':
      return '/static/icons/facebook.svg'
      break;
    case 'LinkedIn' :
      return '/static/icons/icon-linkedin.svg'
      break;
    case 'Instagram' :
      return '/static/icons/instagram.svg'
      break;
    case 'Teléfono' :
      return '/static/icons/icon-phone.svg'
      break;
    case 'Dirección' :
      return '/static/icons/icon-map.svg'
      break;
    case 'Email' :
      return '/static/icons/mail.svg'
      break;
    case 'YouTube' :
      return '/static/icons/youtube.svg'
      break;
    case 'Twitter' :
      return '/static/icons/twitter.svg'
      break;
    default:
      return '/static/icons/icon-linkedin.svg'
      break;
  }
}


