// lazy image
// import "lazysizes";
// import "lazysizes/plugins/attrchange/ls.attrchange";
// import "lazysizes/plugins/object-fit/ls.object-fit";
// import "lazysizes/plugins/parent-fit/ls.parent-fit";


import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";

import {CollapseDrawerProvider} from "./contexts/CollapseDrawerContext";

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {CartProvider} from "./contexts/CartContext";
import {AlertProvider} from "./contexts/AlertContext";
import {AuthProvider} from "./contexts/AuthContext";


// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <AlertProvider>
        <AuthProvider>
          <CollapseDrawerProvider>
            <CartProvider>
              <App/>
            </CartProvider>
          </CollapseDrawerProvider>
        </AuthProvider>
      </AlertProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
)
;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
