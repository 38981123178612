import React, { useState, createContext } from 'react';

export const CartContext = createContext();

export function CartProvider({children}) {
  const [items, setItems] = useState([]);
  const [services, setServices] = useState([]);

  function addItemToCart(newItem) {
    const actionToDo = JSON.parse(sessionStorage.getItem('actionToDo'));
    const productId = newItem._id;
    for (let i = 0; i < items.length; i ++) {
      if (items[i]._id === productId) {
        const duplicated = items.findIndex(element => element._id === items[i]._id);
        items.splice(duplicated, 1);
      }
    }
    setItems(prevState => ([...prevState, {...newItem, actionToDo}]))
  }

  function addServiceToCart(newService) {
    const serviceId = newService._id;

    if (services.some(x => x._id === serviceId)) {
      let index = services.findIndex(x => x._id === serviceId);
      const copyServices = [...services];
      copyServices.splice(index, 1);
      setServices(copyServices);
    } else {
      setServices(prevState => ([...prevState, newService]))
    }
  }

  function removeItemFromCart(itemToRemove) {
    const index = items.findIndex(item => item._id === itemToRemove._id);
    const copyItems = [...items];
    copyItems.splice(index, 1);
    setItems(copyItems);
  }

  function clearCart() {
    setItems([]);
    setServices([]);
  }

  const value = {
    items,
    services,
    addItem: addItemToCart,
    addService: addServiceToCart,
    removeItem: removeItemFromCart,
    clear: clearCart,
  };

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
}
