import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

LogoSilbertec.propTypes = {
  sx: PropTypes.object,
};

export default function LogoSilbertec({ sx, src }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <Box
        component="img"
        // src="/static/img/logo-silbertec.png"
        src={src}
        alt="Logo Silbertec"
        width="100%"
        height="100%"
      />
    </Box>
  );
}
