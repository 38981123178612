import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Container, Divider, Link, Grid, Skeleton, Typography } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { grey } from "@mui/material/colors";

//
import LogoSilbertec from "../../components/portal/LogoSilbertec";
import MHidden from "../../components/portal/@material-extend/MHidden";
import { switchLogo } from "../../utils/helpers";
import useGetFooter from "../../hooks/portal/useGetFooter";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.default,
  borderTop: ".6rem solid " + grey[100],
  // boxShadow: '0px -5px 6px -3px lightgray',
  borderBottom: "1rem solid " + theme.palette.primary.main,
  [theme.breakpoints.up("md")]: {
    // borderTop: "3rem solid " + grey[100],
  },
}));

// como-funciona-la-plataforma

// function Links({data}) {
//   return (
//     <Grid container>
//       <Grid item xs={12} md={4}>
//         <Typography variant="caption" sx={{fontFamily: "Din-STD-Engschrift"}}>
//           POLÍTICAS DE PRIVACIDAD
//         </Typography>
//       </Grid>
//
//       <Grid item xs={12} md={4} sx={{my: {xs: 1, md: 0}}}>
//         <Typography variant="caption" sx={{fontFamily: "Din-STD-Engschrift"}}>
//           CONDICIONES DE COMPRA
//         </Typography>
//       </Grid>
//
//       <Grid item xs={12} md={4}>
//         <Typography variant="caption" sx={{fontFamily: "Din-STD-Engschrift"}}>
//           TÉRMINOS LEGALES
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// }

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { pathname } = useLocation()
  const { logo, social, download, loading } = useGetFooter()


  return (
    <RootStyle sx={{ pb: pathname.includes('preguntas') || pathname.includes('maquinas') && '3rem' }}>
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ alignSelf: "center"}}
          >

            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xs={6}
                md={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {
                  loading && <Skeleton variant='rectangular' width={138} height={62} />
                }
                {
                  !loading && logo && logo.some((x) => x.Titulo === 'Logotipo GoTec') &&
                  <LogoSilbertec
                    src={logo.filter((x) => x.Titulo === 'Logotipo GoTec')[0].Valor}
                    sx={{
                      height: {xs: 42, sm: 62},
                      // height: 'auto',
                      width: 'auto',
                      display: "block",
                      mx: 0,
                      // mb: { xs: 2, md: 0 },
                    }}
                  />
                }
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sx={{ display: "flex", alignItems: "center", pr: 1 }}
              >
                {
                  loading && <Skeleton variant='rectangular' width={138} height={62} />
                }
                {
                  !loading && logo && logo.some((x) => x.Titulo === 'Logotipo Silbertec') &&
                  <LogoSilbertec
                    src={logo.filter((x) => x.Titulo === 'Logotipo Silbertec')[0].Valor}
                    sx={{
                      height: { xs: 42, sm: 62 },
                      width: { xs: 130, sm: 190 },
                      // width: '100%',
                      // height: '100%',
                      display: "block",
                      ml: { xs: "auto", md: 0 },
                    }}
                  />
                }
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ my: { xs: 2, md: 4 }, textAlign: "left"  }}
            >
              {
                loading && <Skeleton variant='rectangular' width={138} height={62} />
              }
              {
                !loading && logo &&
                <>
                  <Typography variant="caption" sx={{ fontFamily: "Din-STD-Engschrift" }} >
                    CERTIFICACIONES
                  </Typography>
                  <LogoSilbertec
                    src={"/static/img/iso-gotec.jpg"}
                    sx={{
                      height: 'auto',
                      width: { xs: 130, sm: 180 },
                      display: "block",
                      mx: 0,
                      mt: { xs: 2, md: 1 },
                    }}
                  />
                </>
              }
            </Grid>

          </Grid>


          <Grid item xs={12} md={6} sx={{ mt: { xs: 2, md: 0 } }}>
            <Link
              component={RouterLink}
              to="/como-funciona-la-plataforma"
              sx={{ color: "#000", "&:hover": { opacity: 0.7 }, cursor: "pointer", }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: 2,
                  mx: 2,
                  "&:hover": { opacity: 0.7 },
                }}
              >
                <ExpandCircleDownIcon
                  sx={{ transform: "rotate(-90deg)", mr: 1 }}
                  color="primary"
                />
                <Typography variant="caption">
                  ¿Cómo funciona la plataforma?
                </Typography>
              </Box>
            </Link>

            <MHidden width="mdUp">
              <Divider />
              {
                loading && <Typography variant='h4'><Skeleton /></Typography>
              }
              {
                !loading && download && download.length > 0 &&

                <Box m={2}>
                  <Grid container>
                    {download.map((item) => {
                      return (
                        <Grid item xs={12} md={4} key={item._id} sx={{ pb: { xs: 1 }, textAlign: 'left' }}>
                          <Typography variant="caption" sx={{ fontSize: '0.875rem', fontFamily: "Din-STD-Engschrift", letterSpacing: '0.8px', textTransform: "uppercase" }}>
                            <RouterLink style={{ color: '#000', textDecoration: 'none' }} to={item.Valor} target="_blank" download>{item.Titulo}</RouterLink>
                          </Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              }
            </MHidden>

            <Divider />

            <Box m={2} textAlign="left">
              {
                loading &&
                <>
                  <Typography variant='h4'><Skeleton /></Typography>
                  <Typography variant='h4'><Skeleton /></Typography>
                  <Typography variant='h4'><Skeleton /></Typography>
                </>
              }
              <Grid container spacing={2}>
                {
                  !loading && social && social.map((link) => {
                    return (
                      <Grid item xs={12} sm={6} key={link._id}>
                        <Typography variant='caption'>
                          {link.Titulo}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            mt: "3px",
                            "&:hover": { opacity: 0.7 },
                          }}
                        >
                          <LogoSilbertec
                            src={switchLogo(link.Titulo)}
                            sx={{ mr: 1, minWidth: '24px', minHeight: '24px', maxWidth: '25px', maxHeight: '25px' }}
                          />
                          <Typography variant="caption">
                            <a
                              href={`${link.Titulo === 'Email' ? 'mailto:' : link.Titulo === 'Teléfono' ? 'tel:' : ''}${link.Valor}`}
                              style={{ color: "#000", textDecoration: 'none', "&:hover": { opacity: 0.7, textDecoration: 'underline' }, cursor: "pointer" }}
                              target="_blank"
                              rel="noreferrer">
                              {link.Valor}
                            </a>
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Box>

            <MHidden width="mdDown">
              <Divider />
              {
                loading && <Typography variant='h4'><Skeleton /></Typography>
              }
              {
                !loading && download && download.length > 0 &&

                <Box mt={2} mx={2}>
                  <Grid container>
                    {download.map((item) => {
                      return (
                        <Grid item xs={12} md={4} key={item._id}>
                          <Typography variant="caption" sx={{ fontFamily: "Din-STD-Engschrift", textTransform: "uppercase" }}>
                            <a style={{ color: '#000', textDecoration: 'none' }} href={item.Valor} target="_blank" rel="noreferrer" download>{item.Titulo}</a>
                          </Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              }
            </MHidden>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
