// ----------------------------------------------------------------------

const adminMenuConfig = [
  {
    title: "Home",
    svgIcon: '/static/icons/check-circle.svg',
    path: "/mantenedor/home",
  },
  {
    title: "Máquinas",
    svgIcon: '/static/icons/panel-de-control.svg',
    path: "/mantenedor/maquinas",
  },
  {
    title: "¿Quieres vender?",
    svgIcon: '/static/icons/mejor-precio.svg',
    path: "/mantenedor/quieres-vender",
  },
  {
    title: "Servicios",
    svgIcon: '/static/icons/Maintenance-tools.svg',
    path: "/mantenedor/servicios",
  },
  {
    title: "Promesa Go!Tec",
    svgIcon: '/static/icons/promesa-de-menique.svg',
    path: "/mantenedor/promesa-go-tec",
  },
  {
    title: "Noticias",
    svgIcon: '/static/icons/XMLID_323_.svg',
    path: "/mantenedor/noticias",
  },
  {
    title: "Preguntas frecuentes",
    svgIcon: '/static/icons/conversacion.svg',
    path: "/mantenedor/preguntas-frecuentes",
  },
  {
    title: "Cómo funciona la plataforma",
    svgIcon: '/static/icons/consultoria.svg',
    path: "/mantenedor/como-funciona-la-plataforma",
  },
  {
    title: "Cotizaciones",
    svgIcon: '/static/icons/formulario-de-contacto.svg',
    path: "/mantenedor/cotizaciones",
  },
  {
    title: "Solicitudes de contacto",
    svgIcon: '/static/icons/formulario-de-contacto.svg',
    path: "/mantenedor/contacto",
  },
  {
    title: "Solicitudes de reserva",
    svgIcon: '/static/icons/formulario-de-contacto.svg',
    path: "/mantenedor/reserva",
  },
  {
    title: "Solicitudes de venta",
    svgIcon: '/static/icons/formulario-de-contacto.svg',
    path: "/mantenedor/ventas",
  },
  {
    title: "Solicitudes de encargo",
    svgIcon: '/static/icons/formulario-de-contacto.svg',
    path: "/mantenedor/encargos",
  },
  {
    title: "Notificaciones de disponibilidad",
    svgIcon: '/static/icons/formulario-de-contacto.svg',
    path: "/mantenedor/disponibilidad-de-maquinas",
  },
];

export default adminMenuConfig;
