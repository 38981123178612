import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
// mantenedorAdmin
import LoadingScreen from "../components/portal/LoadingScreen";
import AdminLayout from "../layouts/admin";
import ProtectedRoute from "./protectedRoute";


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Admin Routes
    {
      path: "/mantenedor",
      element: (
        <ProtectedRoute>
          <AdminLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: '', element: <Navigate replace to='home'/> },
        { path: 'home', element: <AdminHome /> },
        { path: 'maquinas', element: <AdminMachines /> },
        { path: 'maquinas/crear', element: <AdminCreateMachine /> },
        { path: 'maquinas/preview', element: <AdminPreviewMachine /> },
        { path: 'maquinas/editar/:id', element: <AdminCreateMachine /> },
        { path: 'quieres-vender', element: <AdminSell /> },
        { path: 'servicios', element: <AdminServices /> },
        { path: 'promesa-go-tec', element: <AdminPromiseGoTec /> },
        { path: 'noticias', element: <AdminNews /> },
        { path: 'noticias/crear', element: <AdminCreateNews /> },
        { path: 'noticias/editar/:id', element: <AdminCreateNews /> },
        { path: 'preguntas-frecuentes', element: <AdminFrequentlyAskedQuestions /> },
        { path: 'preguntas-frecuentes/crear', element: <AdminCreateQuestion /> },
        { path: 'preguntas-frecuentes/editar/:id', element: <AdminCreateQuestion /> },
        { path: 'como-funciona-la-plataforma', element: <AdminHowItWorks /> },
        { path: 'contacto', element: <ContactSolicitudes />},
        { path: 'reserva', element: <ReserveSolicitudes />},
        { path: 'ventas', element: <SellSolicitudes />},
        { path: 'Encargos', element: <BuySolicitudes />},
        { path: 'disponibilidad-de-maquinas', element: <NotifySolicitudes />},
        { path: 'cotizaciones', element: <QuoteSolicitudes />},
      ]
    },
    // Main Routes
    {
      /* COMING SOON ON */
      path: "*",
      // path: "/",
      /* COMING SOON ON */
      element: <LogoOnlyLayout />,
      children: [
        /* COMING SOON ON */
        { path: "proximamente", element: <ComingSoon /> },
        // { path: "", element: <ComingSoon /> },
        /* COMING SOON ON */
        // { path: "maintenance", element: <Maintenance /> },
        { path: "login", element: <Login /> },
        // { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    /* COMING SOON ON */
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "", element: <LandingPage /> },
        { path: "busqueda", element: <Searcher /> },
        { path: "preguntas-frecuentes", element: <FrequentlyAskedQuestions /> },
        { path: "como-funciona-la-plataforma", element: <WhatIsGoTec /> },
        // { path: "about-us", element: <About /> },
        { path: "servicios", element: <Services /> },
        { path: 'promesa-go-tec', element: <PromiseGoTec /> },
        // { path: "contact-us", element: <Contact /> },
      ],
    },
    {
      path: '/encarga-tu-maquina',
      element: <MainLayout />,
      children: [
        {path: '', element: <OrderYourMachine />},
        {path: 'orden-recibida/:id/exito', element: <FormSuccess />},
        {path: 'error', element: <FormFail />}
      ]
    },
    {
      path: '/noticias',
      element: <MainLayout />,
      children: [
        {path: '', element: <News />},
        {path: ':slug', element: <NewsDetail />}
      ]
    },
    {
      path: '/maquinas',
      element: <MainLayout />,
      children: [
        {path: '', element: <Machines/>},
        {path: ':slug', element: <ProductDetail />},
        {path: ':id/notificarme-cuando-este-disponible', element: <NotifyMe />},
        {path: ':id/notificarme-cuando-este-disponible/orden-recibida/:form/exito', element: <ReserveSuccess />},
        {path: ':id/notificarme-cuando-este-disponible/error', element: <ReserveFail />},
        {path: ':id/reservar', element: <Reserve />},
        {path: ':id/reservar/orden-recibida/:form/exito', element: <ReserveSuccess />},
        {path: ':id/reservar/error', element: <ReserveFail />},
      ]
    },
    {
      path: '/quieres-vender',
      element: <MainLayout />,
      children: [
        {path: '', element: <Sell/>},
        {path: 'formulario', element: <SellForm />},
        {path: 'formulario/orden-recibida/:id/exito', element: <FormSuccess />},
        {path: 'formulario/error', element: <FormFail />},
      ]
    },
    {
      path: '/carrito',
      element: <MainLayout />,
      children: [
        {path: '', element: <Cart />},
        {path: 'confirmar', element: <Confirmation />},
        {path: ':id/exito', element: <Success />},
        {path: 'error', element: <Fail />},
      ]
    },
    /* COMING SOON ON */
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
// const Login = Loadable(lazy(() => import("../pages/portal/authentication/Login")));
// Main
const LandingPage = Loadable(lazy(() => import("../pages/portal/LandingPage")));
const Searcher = Loadable(lazy(() => import('../pages/portal/Searcher')));
const OrderYourMachine = Loadable(lazy(() => import("../pages/portal/OrderYourMachine")));
const WhatIsGoTec = Loadable(lazy(() => import("../pages/portal/WhatIsGoTec")));
const FrequentlyAskedQuestions = Loadable(lazy(() => import("../pages/portal/FrequentlyAskedQuestions")));
// const About = Loadable(lazy(() => import("../pages/portal/About")));
// const Contact = Loadable(lazy(() => import("../pages/portal/Contact")));
const ComingSoon = Loadable(lazy(() => import("../pages/portal/ComingSoon")));
// const Maintenance = Loadable(lazy(() => import("../pages/portal/Maintenance")));
// const Page500 = Loadable(lazy(() => import("../pages/portal/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/portal/Page404")));
const Services = Loadable(lazy(() => import("../pages/portal/Services")));
const PromiseGoTec = Loadable(lazy(() => import("../pages/portal/PromiseGoTec")));

//Cart
const Cart = Loadable(lazy(() => import('../pages/portal/cart/Cart')));
const Confirmation = Loadable(lazy(() => import('../pages/portal/cart/Confirmation')));
const Success = Loadable(lazy(() => import('../pages/portal/cart/Success')));
const Fail = Loadable(lazy(() => import('../pages/portal/cart/Fail')));

//machines || products
const ProductDetail = Loadable(lazy(() => import("../pages/portal/products/ProductDetail")));
const Machines = Loadable(lazy(() => import("../pages/portal/products/Machines")));
const Reserve = Loadable(lazy(() => import('../pages/portal/products/Reserve')));
const NotifyMe = Loadable(lazy(() => import('../pages/portal/products/NotifyMe')));
const ReserveSuccess = Loadable(lazy(() => import('../pages/portal/products/ReserveSuccess')));
const ReserveFail = Loadable(lazy(() => import('../pages/portal/products/ReserveFail')));

//Sell
const Sell = Loadable(lazy(() => import("../pages/portal/sell/Sell")));
const SellForm = Loadable(lazy(() => import("../pages/portal/sell/Form")));
const FormSuccess = Loadable(lazy(() => import("../pages/portal/sell/FormSuccess")));
const FormFail = Loadable(lazy(() => import("../pages/portal/sell/FormFail")));

// news
const News = Loadable(lazy(() => import("../pages/portal/news/News")));
const NewsDetail = Loadable(lazy(() => import('../pages/portal/news/NewsDetail')));

//admin
const Login = Loadable(lazy(() => import('../pages/portal/authentication/Login')))
const AdminHome = Loadable(lazy(() => import('../pages/mantenedorAdmin/Home')));
const AdminMachines = Loadable(lazy(() => import('../pages/mantenedorAdmin/machines/Machines')));
const AdminSell = Loadable(lazy(() => import('../pages/mantenedorAdmin/Sell')));
const AdminServices = Loadable(lazy(() => import('../pages/mantenedorAdmin/Services')));
const AdminPromiseGoTec = Loadable(lazy(() => import('../pages/mantenedorAdmin/PromiseGoTec')));
const AdminNews = Loadable(lazy(() => import('../pages/mantenedorAdmin/News/News')));
const AdminHowItWorks = Loadable(lazy(() => import('../pages/mantenedorAdmin/HowItWorks')));
const AdminCreateNews = Loadable(lazy(() => import('../pages/mantenedorAdmin/News/CreateNews')));
const AdminFrequentlyAskedQuestions = Loadable(lazy(() => import('../pages/mantenedorAdmin/questions/FrequentlyAskedQuestions')));
const AdminCreateMachine = Loadable(lazy(() => import('../pages/mantenedorAdmin/machines/create')));
const AdminPreviewMachine = Loadable(lazy(() => import('../pages/mantenedorAdmin/machines/AdminPreviewMachine')));
const AdminCreateQuestion = Loadable(lazy(() => import('../pages/mantenedorAdmin/questions/CreateQuestion')));

const ContactSolicitudes = Loadable(lazy(() => import('../pages/mantenedorAdmin/forms/Contact')));
const ReserveSolicitudes = Loadable(lazy(() => import('../pages/mantenedorAdmin/forms/Reserve')));
const SellSolicitudes = Loadable(lazy(() => import('../pages/mantenedorAdmin/forms/Sell')));
const BuySolicitudes = Loadable(lazy(() => import('../pages/mantenedorAdmin/forms/Buy')));
const NotifySolicitudes = Loadable(lazy(() => import('../pages/mantenedorAdmin/forms/Notify')));
const QuoteSolicitudes = Loadable(lazy(() => import('../pages/mantenedorAdmin/forms/Quote')));
