import Router from "./routes";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// mantenedorAdmin
import ScrollToTop from "./components/portal/ScrollToTop";
import GoogleAnalytics from "./components/portal/GoogleAnalytics";
import {ProgressBarStyle} from "./components/portal/LoadingScreen";
import {ConfirmationModalContextProvider} from "./contexts/ConfirmationModalContext";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// ----------------------------------------------------------------------

export default function App() {

  return (
    <ThemeConfig>
      <GlobalStyles/>
      <ProgressBarStyle/>
      <ScrollToTop/>
      <TawkMessengerReact
                propertyId="62c595aab0d10b6f3e7b1a9a"
                widgetId="1g79t18pa"/>
      <GoogleAnalytics/>
      <ConfirmationModalContextProvider>
        <Router/>
      </ConfirmationModalContextProvider>
    </ThemeConfig>
  );
}
