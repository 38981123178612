import { Outlet } from "react-router-dom";
//
import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";
import {useLocation} from "react-router-dom";
import GlobalSnackbar from "../../components/mantenedorAdmin/GlobalSnackbar";
import * as React from "react";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation()
  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>
      <GlobalSnackbar />

      {
        !pathname.includes('carrito') &&
        <MainFooter />
      }
    </>
  );
}
