import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import fileFill from "@iconify/icons-eva/file-fill";
import roundGrain from "@iconify/icons-ic/round-grain";
import bookOpenFill from "@iconify/icons-eva/book-open-fill";

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Máquinas",
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    svgIcon: '/static/icons/panel-de-control.svg',
    path: "/maquinas",
  },
  {
    title: "¿Quieres vender?",
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    svgIcon: '/static/icons/mejor-precio.svg',
    path: "/quieres-vender",
  },
  {
    title: "Servicios",
    icon: <Icon icon={fileFill} {...ICON_SIZE} />,
    svgIcon: '/static/icons/Maintenance-tools.svg',
    path: "/servicios",
  },
  {
    title: "Promesa Go!Tec",
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    svgIcon: '/static/icons/promesa-de-menique.svg',
    path: "/promesa-go-tec",
  },
  {
    title: "Encarga tu máquina",
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    svgIcon: '/static/icons/formulario-de-contacto.svg',
    path: "/encarga-tu-maquina",
  },
  {
    title: "Noticias",
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    svgIcon: '/static/icons/XMLID_323_.svg',
    path: "/noticias",
  },
  {
    title: "Preguntas frecuentes",
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    svgIcon: '/static/icons/conversacion.svg',
    path: "/preguntas-frecuentes",
  },
];

export default menuConfig;
