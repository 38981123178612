import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import SvgIconStyle from "../../components/portal/SvgIconStyle";
import adminMenuConfig from "./AdminMenuConfig";
import GlobalSnackbar from "../../components/mantenedorAdmin/GlobalSnackbar";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {IconButton} from "@mui/material";
import {AuthContext} from "../../contexts/AuthContext";
import {useContext} from "react";

const drawerWidth = 240;

export default function AdminLayout() {
  const { pathname } = useLocation()
  const { logout } = useContext(AuthContext)
  const navigate = useNavigate()

  function goTo(path) {
    return navigate(path)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/*<AppBar*/}
      {/*  position="fixed"*/}
      {/*  sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}*/}
      {/*>*/}
      {/*  <Toolbar>*/}
      {/*    <Typography variant="h6" noWrap component="div">*/}
      {/*      Permanent drawer*/}
      {/*    </Typography>*/}
      {/*  </Toolbar>*/}
      {/*</AppBar>*/}
      {
        pathname !== '/mantenedor/maquinas/preview' &&
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              backgroundColor: '#3E5866',
              color: '#fff',
              border: '1px solid lightgray',
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar sx={{ display: 'flex', justifyContent:'space-between', backgroundColor: '#fff' }}>
            <Box
              component='img'
              width={70}
              sx={{ p: 1 }}
              src='/static/icons/go-tec.svg'
            />
            <IconButton onClick={() => logout()}>
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            {
              adminMenuConfig.map((element) => (
                <ListItem
                  onClick={ () => goTo(element.path)}
                  button
                  key={element.path}
                  sx={{
                    "&:hover" : {
                      backgroundColor: '#fff',
                      color: '#3E5866'
                    },
                    display: 'flex',
                    justifyContent: 'start',
                    backgroundColor: pathname.includes(element.path) && '#fff',
                    color: pathname.includes(element.path) && '#3E5866'
                  }}>
                  <ListItemIcon>
                    <SvgIconStyle src={element.svgIcon}/>
                  </ListItemIcon>
                  <Typography  sx={{ fontSize: '10px', textTransform: 'uppercase', fontWeight: '600' }}>
                    {element.title}
                  </Typography>
                </ListItem>
              ))
            }
          </List>
        </Drawer>
      }

      <GlobalSnackbar />
      <Outlet />
    </Box>
  );
}
