import { Helmet } from 'react-helmet-async';
// import { googleAnalyticsConfig } from '../../config';

// ----------------------------------------------------------------------

const GA_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER;
const GA_UNIVERSAL_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_UNIVERSAL;

export default function GoogleAnalytics() {
  return (
    <>
      <Helmet>
        {/* <!-- Google Tag Manager --> */}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${GA_MEASUREMENT_ID}');`}</script>
        {/* <!-- End Google Tag Manager --> */}
        {/* <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script> */}
      {/* <!-- Google tag (gtag.js) - Google Analytics --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-238305604-1">
      </script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${GA_UNIVERSAL_ID}');`}
      </script>
      </Helmet>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${GA_MEASUREMENT_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
}
